<template>
    <div>
        <LList
            class="px-3"
            analytics-prefix="Jobs"
            :columns="columns"
            :companies="[]"
            :items="jobs"
            model-name="Job"
            :loading="loading"
            :fetching="fetching"
            :total-item-count="totalJobCount"
            :tooltip-wording="tooltipWording"
            @fetch="fetch"
        >
            <template slot="action-buttons">
                <button
                    v-if="canManage"
                    data-cy="runJobBtn"
                    class="btn btn-primary col-xs-12 col-lg-auto"
                    aria-label="Run Job"
                    @click="$emit('run-job')"
                >
                    <FontAwesomeIcon
                        icon="play"
                        class="mr-1"
                    />
                    Run Job
                </button>
            </template>

            <template #list-item-action-buttons="{item}">
                <RouterLink
                    :id="`viewJob${item.id}`"
                    class="btn btn-link btn-admin mr-2"
                    aria-label="View Job"
                    :to="{name: 'admin.job.detail', params: {id: item.id}}"
                >
                    <FontAwesomeIcon
                        class="mr-0"
                        icon="eye"
                        size="2x"
                    />
                </RouterLink>

                <BTooltip
                    :target="`viewJob${item.id}`"
                    placement="left"
                    title="View Job Details"
                />
            </template>
        </LList>

        <RouterView />
    </div>
</template>

<script>
    import LList from '@imt/vue-list/src/components/LList.vue';
    import {asString} from '@imt/vue-list/src/utils/queryParams';
    import dateMixin from '@imt/vue-toolbox/src/mixins/date';
    import {
        mapActions,
        mapGetters,
        mapState,
    } from 'vuex';

    import authMixin from '@/mixins/auth';

    export default {
        name: 'BHJobs',
        components: {
            LList,
        },
        mixins: [
            authMixin,
            dateMixin,
        ],
        props: {
            mode: {
                type: String,
                required: false,
                default: 'light',
            },
        },
        data() {
            return {
                loading: true,
                fetching: false,
                totalJobCount: 0,
                tooltipWording: {
                    search_field_display: {},
                    search_field_names: {
                        search: 'Status',
                    },
                },
                columns: [
                    {
                        key: 'id',
                        class: 'col-2 col-lg-3',
                        sortable: true,
                    },
                    {
                        key: 'policy_system',
                        class: 'col-3 d-none d-md-inline text-break',
                        value: (item) => {
                            return item.process?.policySystemId ? this.formattedPolicySystems?.filter(ps => ps.id === item.process.policySystemId.toString())[0]?.title : 'All';
                        },
                    },
                    {
                        key: 'started',
                        class: 'col-5 col-md-4 text-break',
                        value: item => {
                            return `${this.dateFormatted(item.updatedAt)}`;
                        },
                        sortable: true,
                        sortBy: 'updated_at',
                    },
                    {
                        key: 'options',
                        class: 'col-auto options px-0',
                    }
                ],
            };
        },
        computed: {
            ...mapGetters('toolbox', [
                'formattedPolicySystems'
            ]),
            ...mapState([
                'jobs',
            ]),
        },
        async created() {
            await Promise.all([
                this.fetchPolicySystems(),
                this.fetch()
            ]);

            this.loading = false;
        },
        methods: {
            async fetch() {
                this.fetching = true;
                const response = await this.fetchJobs(`include=process${asString(this.$route.query)}`);
                this.totalJobCount = response.data.meta.pagination.count;
                this.fetching = false;
            },
            ...mapActions(['fetchJobs']),
            ...mapActions('toolbox', ['fetchPolicySystems']),
        },
    };
</script>
